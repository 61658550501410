import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        'form',
    ];

    connect() {
        this.modal = document.querySelector('.form-modal');
    };

    submitForm() {
        this.formTarget.reportValidity()

        if (this.formTarget.reportValidity() === true) {
            this.formRequest();
        }
    };

    formRequest() {

        // show loader
        const loader= document.getElementById('sd-loader-form');
        loader.classList.remove('sd-loader-hidden');

        fetch(this.formTarget.action, {
            method: this.formTarget.method,
            body: new FormData(this.formTarget),
        })
        .then(async response => {
            this.element.outerHTML = await response.text();

            // hide loader
            loader.classList.add('sd-loader-hidden');

            if (response.status === 200) {
                this.modal.classList.add('modal-visible');
            }
        });
    };
};