import {Controller} from '@hotwired/stimulus';
import {useVisibility} from "stimulus-use";

export default class extends Controller {
    static targets = [
        'captchaImage',
        'form',
    ];

    connect() {
        useVisibility(this, { eventPrefix: false })
    };

    initialize() {
        this.method = this.formTarget.method;
        this.ajaxRoute = this.formTarget.querySelector('.captcha-form').getAttribute('data-captchaajaxroute');
    };

    refresh() {
        fetch(this.ajaxRoute, {
            method: this.method,
        })
            .then(response=> response.text())
            .then(imageSrc => this.captchaImageTarget.src = imageSrc)
        ;
    };
};