import {Controller} from '@hotwired/stimulus';


export default class extends Controller {

    closeModal() {
        this.element.classList.remove('modal-visible');
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
};